import React from "react";
import MenuLeftComponent from "./components/MenuLeftComponent";
import LandingPage from "../landing/LandingPage";
import { useWindowSize } from "hooks/useWindowSize";

const MainPage: React.FC = () => {
  const { isMobile } = useWindowSize(); 
  const lastScrollYRef = React.useRef(0);

  React.useEffect(() => {
    document.title = "Ogrosa | Wybieraj lokalnie, jedz naturalnie!";
  }, []);

  const handleScroll = () => {
    if (!isMobile) return;

    const isScrollingUp = window.scrollY < lastScrollYRef.current;
    lastScrollYRef.current = window.scrollY;

    const top1ClassName = getStyle("f-top ", "f-show", isScrollingUp);
    const top2ClassName = getStyle("f-page-top ", "f-show-64", isScrollingUp);

    let translateYValue = "0px";
    let top2bg = "";
    if (!isScrollingUp) {
      translateYValue = window.scrollY > 150 ? "0px" : `-${window.scrollY}px`;
    }

    top2bg = window.scrollY < 5 ? "" : `f-page-top-bg`;

    const top1Element = document.getElementById("top-main");
    const top2Element = document.getElementById("top-page");

    if (top1Element) {
      top1Element.className = top1ClassName;
      top1Element.style.transform = `translateY(${translateYValue})`;
    }

    if (top2Element) {
      top2Element.className = `${top2ClassName} ${top2bg}`;
      top2Element.style.transform = `translateY(${translateYValue})`;
    }
  };

  const getStyle = (className: string, show: string, isScrollingUp: boolean): string => {
    let style = `${className}`;

    if (window.scrollY > 100) {
      style += isScrollingUp ? show : "f-hide";
    }

    return style;
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile]);

  if (isMobile) {
    return (
      <div id="main-view" className="f-main">
        <div id="top-main" className="f-top">
          <MenuLeftComponent />
        </div>
        <div className="f-center">
          <LandingPage />
        </div>
      </div>
    );
  } else {
    return (
      <div id="main-view" className="f-main">
        <div id="top1" className="f-top">
          <MenuLeftComponent />
        </div>
        <div className="f-panel box-shadow-5">         
          <div className="f-center">
           <LandingPage/>
          </div>
        </div>
      </div>
    );
  }
};

export default MainPage;
