import "./styles/app.scss";
import MainPage from "./pages/main/MainPage";

import moment from "moment";

declare global {
  interface Date {
    toFormatMoment: (format: string) => string;
  }
}

Date.prototype.toFormatMoment = function (format) {
  var date = new Date(this.valueOf());
  if (date.toString() === "Invalid Date") {
    return "";
  }
  return moment(date).format(format);
};

const App: React.FC = () => {
  return <MainPage />;
};

export default App;
