import { generateGUID } from "commons/stringManager";
import { NotificationType } from "models/Dictionaries";
import { Notify } from "models/Notify";
import { useDispatch } from "react-redux";
import { clearNotification, setNotifications } from "reducers/notificationReducer";
import { NotificationPopUp } from "controls/NotificationPopUp";

let notificationList: Notify[] = [];

export const useNotify = () => {
  const dispatch = useDispatch();

  const addNotify = (notify: Partial<Notify>) => {
    const newNotify: Notify = {
      id: notify.id ?? generateGUID(),
      isShowed: notify.isShowed ?? false,
      isRead: notify.isRead ?? false,
      title: notify.title ?? "Powiadomienie",
      date: notify.date ?? new Date(),
      content: notify.content ?? "",
      type: notify.type ?? NotificationType.Success,
      showIcon: notify.showIcon ?? true,
      link: notify.link,
    };
    notificationList = [...notificationList, newNotify];
    dispatch(setNotifications(notificationList));
    NotificationPopUp.show(newNotify);

    const soundName = newNotify.type === NotificationType.Error ? "audio-notify-error" : "audio-notify";
    const notifySound = document.getElementById(soundName) as HTMLAudioElement;
    notifySound?.play();
  };

  const updateNotify = (notify: Notify) => {
    notificationList = notificationList.map((x) => (x.id === notify.id ? notify : x));
    dispatch(setNotifications(notificationList));
  };

  const deleteNotify = (notify: Notify) => {
    notificationList = notificationList.filter((x) => x.id !== notify.id);
    dispatch(setNotifications(notificationList));
  };

  const deleteAllNotify = () => {
    notificationList = [];
    dispatch(clearNotification());
  };

  return { addNotify, updateNotify, deleteNotify, deleteAllNotify };
};
