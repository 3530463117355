import { generateRandom } from "commons/stringManager";
import { Notify } from "models/Notify";
import { createRoot } from "react-dom/client";
import NotificationItemComponent from "components/notification/NotificationItemComponent";

let notifiList: { id: string; localization: DOMRect }[] = [];
export const NotificationPopUp = {
  show: (content: Notify, delay: number = 200, timeOfVisibility: number = 3000) => {
    let hostEl = document.createElement("div");
    const idElement = generateRandom(10);
    hostEl.id = idElement;

    const removeElement = () => {
      const oldElem = document.querySelectorAll(`#${CSS.escape(idElement)}`);
      relocateComponent(idElement);
      oldElem.forEach((item) => {
        document.body.removeChild(item);
      });
      notifiList = notifiList.filter((x) => x.id !== idElement);
    };

    const addNotification = () => {
      let hostP = <NotificationItemComponent notification={content} />;
      const root = createRoot(hostEl);
      root.render(hostP);

      hostEl.className = "notification-panel";
      hostEl.style.overflow = "hidden";
      hostEl.style.visibility = "hidden";
      hostEl.style.transition = `all 500ms ease-out`;
      setLocalization(hostEl);
      document.body.appendChild(hostEl);

      setTimeout(() => {
        const localization = hostEl.getBoundingClientRect();
        notifiList.push({ id: idElement, localization });
        hostEl.style.visibility = "";
        relocateComponent(undefined);
      }, 100);

      setTimeout(() => {
        removeElement();
      }, timeOfVisibility ?? 1500);
    };

    if (content) {
      setTimeout(() => {
        addNotification();
      }, delay ?? 500);
    }
  },
};

const setLocalization = (hostEl: HTMLDivElement) => {
  const height = getTotalNotificationHeight(notifiList);
  setPosition(hostEl, "right", "0px", "top", `${height + 16}px`);
};

const setPosition = (
  hostEl: HTMLDivElement,
  pos1Key: string,
  pos1Value: string,
  pos2Key: string,
  pos2Value: string
) => {
  //@ts-ignore
  hostEl.style[pos1Key] = pos1Value;
  //@ts-ignore
  hostEl.style[pos2Key] = pos2Value;
};

const getTotalNotificationHeight = (notifiList: any[]) => {
  return notifiList.reduce((a, b) => a + (b.localization.height + 8), 0);
};

const relocateComponent = (idElement: string | undefined) => {
  const nList = notifiList;
  let targetItem = nList.find((x) => x.id === idElement);

  let height = 16;
  if (targetItem) {
    height = -targetItem.localization.height + 8;
  } else {
    targetItem = nList[0];
  }

  const targetIndex = nList.findIndex((x) => x.id === targetItem?.id);

  for (let index = 0; index < targetIndex; index++) {
    const element = nList[index];
    height += element.localization.height + 8;
  }

  for (let index = targetIndex; index < nList.length; index++) {
    const element = nList[index];
    const oldElem = document.querySelector(`#${CSS.escape(element.id)}`) as HTMLDivElement;
    oldElem.style.top = `calc(50px + ${height}px)`;
    height += element.localization.height + 8;
  }
};
