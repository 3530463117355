import { env } from "../env";
import _superagent from "superagent";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "universal-cookie";
import { Token } from "models/Token";
import { ValidationFailure } from "models/ValidationFailure";

const API_ROOT = env.REACT_APP_API;

axios.defaults.withCredentials = true;

const errorService = (err: AxiosError): any => {
  if (axios.isAxiosError(err)) {
    if (err.response) {
      //@ts-ignore
      const validationFailures: ValidationFailure[] = err.response.data?.errors;
      if (validationFailures) {
        return validationFailures;
      }
      //@ts-ignore
      return err.response.data?.message || err.response.data?.detail || "An error occurred";
    }
  }
  console.debug(err);
  return "An unexpected error occurred";
};

export function getWithoutToken<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
  return new Promise(async (resolve, reject) => {
    const response = await axios
      .get(`${API_ROOT}${url}`, { ...config, withCredentials: true })
      .catch((err: AxiosError): void => {
        reject(errorService(err));
      });

    if (response !== undefined) {
      resolve(response.data as R);
    }
  });
}
export function postWithoutToken<T = any, R = AxiosResponse<T>>(url: string, data: any): Promise<R> {
  const json = JSON.stringify(data);
  return new Promise(async (resolve, reject) => {
    const config = { headers: { "Content-Type": "application/json" } };
    const response = await axios.post(`${API_ROOT}${url}`, json, config).catch((err: AxiosError): void => {
      reject(errorService(err));
    });

    if (response !== undefined) {
      resolve(response.data as R);
    }
  });
}

export function get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
  return new Promise(async (resolve, reject) => {
    config = { ...config, headers: { ...config?.headers, Authorization: getToken() } };
    const response = await axios.get(`${API_ROOT}${url}`, config).catch((err: AxiosError): void => {
      reject(errorService(err));
    });

    if (response !== undefined) {
      resolve(convertNullToUndefined(response.data) as R);
    }
  });
}

export function getFile<T = any, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig,
  data?: any
): Promise<R> {
  return new Promise(async (resolve, reject) => {
    config = {
      ...config,
      responseType: "blob",
      params: config?.params,
      headers: {
        ...config?.headers,
        "Content-type": "application/json-patch+json",
        Authorization: getToken(),
      },
    };
    const response = await axios.post(`${API_ROOT}${url}`, data, config).catch((err: AxiosError): void => {
      reject(errorService(err));
    });

    if (response !== undefined) {
      resolve(response.data as R);
    }
  });
}

export function post<T = any, R = AxiosResponse<T>>(url: string, data: any): Promise<R> {
  const json = JSON.stringify(data);
  return new Promise(async (resolve, reject) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    };
    const response = await axios.post(`${API_ROOT}${url}`, json, config).catch((err: AxiosError): void => {
      reject(errorService(err));
    });

    if (response !== undefined) {
      resolve(response.data as R);
    }
  });
}

export function postFile<T = any, R = AxiosResponse<T>>(url: string, formData: FormData): Promise<R> {
  return new Promise(async (resolve, reject) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getToken(),
      },
    };
    const response = await axios.post(`${API_ROOT}${url}`, formData, config).catch((err: AxiosError): void => {
      reject(errorService(err));
    });

    if (response !== undefined) {
      resolve(response.data as R);
    }
  });
}

export function put<T = any, R = AxiosResponse<T>>(url: string, data: any): Promise<R> {
  return new Promise(async (resolve, reject) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    };
    const response = await axios.put(`${API_ROOT}${url}`, data, config).catch((err: AxiosError): void => {
      reject(errorService(err));
    });

    if (response !== undefined) {
      resolve(response.data as R);
    }
  });
}

export function del<T = any, R = AxiosResponse<T>>(url: string): Promise<R> {
  return new Promise(async (resolve, reject) => {
    const config = { headers: { "Content-Type": "application/json", Authorization: getToken() } };
    const response = await axios.delete(`${API_ROOT}${url}`, config).catch((err: AxiosError): void => {
      reject(errorService(err));
    });

    if (response !== undefined) {
      resolve(response.data as R);
    }
  });
}

function convertNullToUndefined(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null) {
        obj[key] = undefined;
      } else if (typeof obj[key] === "object") {
        convertNullToUndefined(obj[key]);
      }
    }
  }
  return obj;
}
function convertDateStringsToDates(obj: any) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
        obj[key] = new Date(value);
      } else if (typeof value === "object" && value !== null) {
        convertDateStringsToDates(value);
      }
    }
  }
}
export const getToken = (): string => {
  const cookies = new Cookies();
  const token: Token = cookies.get("token");

  if (!token) {
    throw new Error("Wymagane logowanie do platformy");
  }

  return `Bearer ${token.accessToken}`;
};
