import React from "react";
import * as NewsletterApi from "api/NewsletterApi";
import { Button } from "controls/Button";
import { InputCheckBox } from "controls/InputCheckBox";
import { InputText } from "controls/InputText";
import { Label } from "controls/Label";
import { useWindowSize } from "hooks/useWindowSize";
import { LandingState, LandingValideError } from "models/State";
import { useDispatch, useSelector } from "react-redux";
import { changeLandingState } from "reducers/landingReducer";
import InfoBox from "controls/InfoBox";
import { ValidationFailure } from "models/ValidationFailure";
import { NotificationType } from "models/Dictionaries";
import { useNotify } from "hooks/useNotify";

const LandingCTAComponent: React.FC = () => {
  const landingState: LandingState = useSelector((state: any) => state.landing);
  const { isMobile } = useWindowSize();
  const { addNotify } = useNotify();
  const dispatch = useDispatch();

  const send = async () => {
    await NewsletterApi.addNewsletter({
      email: landingState.email,
      approval: landingState.approval,
      side: landingState.who,
    })
      .then((value) => {
        addNotify({
          title: "Podziękowania",
          content:
            "Twój email został pomyślnie zapisany w naszej bazie. Witamy w naszej społecznośći, za chwilę otrzymasz emaila powitalnego",
          type: NotificationType.Success,
        });
        dispatch(changeLandingState({ email: "", approval: false }));
      })
      .catch((err) => setError(err));
  };

  const setError = (err: ValidationFailure[] | string) => {
    if (typeof err === "string") {
      addNotify({
        title: "Błąd",
        content: `Wystąpił błąd podczas zapisu do newslettera`,
        type: NotificationType.Error,
      });
    } else {
      const valideError: LandingValideError = {
        email: err?.find((x) => x.PropertyName?.toLowerCase() === "email")?.ErrorMessage ?? "",
        approval: err?.find((x) => x.PropertyName?.toLowerCase() === "approval")?.ErrorMessage ?? "",
      };
      dispatch(changeLandingState({ error: valideError }));
    }
  };

  if (isMobile) {
    if (landingState.who === "client") {
      return (
        <InfoBox
          id="cta-landing"
          className="width-fill mag-4"
          sizeFrame="md"
          style={{ marginBlock: "64px", borderRadius: "35px" }}
        >
          <div className="d-flex fd-c ai-center gap-16">
            <img className="l-img-box-cta" src="/img/client_eat.jpg" />
            <Label
              wrap
              fontColor="black"
              style={{ fontSize: "20px" }}
              fontStyle="extra-bold"
              content={`Chcesz zobaczyć aplikację przed premierą?`}
            />
            <Label
              wrap
              fontColor="black"
              style={{ fontSize: "16px" }}
              fontStyle="semibold"
              content={`Dołącz do zamkniętych testów i zyskaj priorytetowy dostęp do zdrowej i naturalnej żywności przed wszystkimi! Zostaw nam swojego emaila, a będziemy Cię informować o postępach prac, oraz kolejnych nowościach związanych z naszym portalem, który ceni zdrowe jedzenie i wspiera lokalną gospodarkę.`}
            />
            <InputText
              frameClassName="width-fill"
              sizeFrame="md"
              type="email"
              autoComplete="email"
              kind="tertiary"
              placeholder="Wprowadź email"
              value={landingState.email}
              error={landingState.error.email}
              onChange={(e) =>
                dispatch(
                  changeLandingState({
                    email: e.currentTarget.value,
                    error: { email: "", approval: landingState.error.approval },
                  })
                )
              }
            />
            <InputCheckBox
              sizeFrame="sml"
              kind="transparent"
              checkboxLabel="Wyrażam zgodę na otrzymywanie newslettera oraz informacji marketingowych od Ogrosa na podany adres e-mail zgodnie z Polityką Prywatności. Subskrypcja jest dobrowolna i mogę wycofać zgodę w każdej chwili."
              fontColor="black"
              fontSize="sml"
              fontStyle="semibold"
              checked={landingState.approval}
              error={landingState.error.approval}
              onChange={(e) =>
                dispatch(
                  changeLandingState({
                    approval: e.target.checked,
                    error: { approval: "", email: landingState.error.email },
                  })
                )
              }
            />
            <Button
              className="width-fill mag-b-8"
              sizeFrame="lg"
              kind="primary"
              fontStyle="bold"
              fontSize="lg"
              label="Dołącz teraz!"
              onClick={send}
            />
          </div>
        </InfoBox>
      );
    }

    return (
      <InfoBox
        id="cta-landing"
        className="width-fill mag-4"
        sizeFrame="md"
        style={{ marginBlock: "64px", borderRadius: "35px" }}
      >
        <div className="d-flex fd-c ai-center gap-16">
          <img className="l-img-box-cta" src="/img/suppliers.jpg" />
          <Label
            wrap
            fontColor="black"
            style={{ fontSize: "20px" }}
            fontStyle="extra-bold"
            content={`Chcesz zobaczyć aplikację przed premierą?`}
          />
          <Label
            wrap
            fontColor="black"
            style={{ fontSize: "16px" }}
            fontStyle="semibold"
            content={`Dołącz do zamkniętych testów i zyskaj darmowe miesiące użytkowania po oficjalnym uruchomieniu. Zostaw nam swojego emaila, a będziemy Cię informować o kolejnych nowościach związanych z naszym portalem, który ceni zdrowe jedzenie i wspiera lokalną gospodarkę.`}
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="md"
            type="email"
            autoComplete="email"
            kind="tertiary"
            placeholder="Wprowadź email"
            value={landingState.email}
            error={landingState.error.email}
            onChange={(e) =>
              dispatch(
                changeLandingState({
                  email: e.currentTarget.value,
                  error: { email: "", approval: landingState.error.approval },
                })
              )
            }
          />
          <InputCheckBox
            sizeFrame="sml"
            kind="transparent"
            checkboxLabel="Wyrażam zgodę na otrzymywanie newslettera oraz informacji marketingowych od Ogrosa na podany adres e-mail zgodnie z Polityką Prywatności. Subskrypcja jest dobrowolna i mogę wycofać zgodę w każdej chwili."
            fontColor="black"
            fontSize="sml"
            fontStyle="semibold"
            checked={landingState.approval}
            error={landingState.error.approval}
            onChange={(e) =>
              dispatch(
                changeLandingState({
                  approval: e.target.checked,
                  error: { approval: "", email: landingState.error.email },
                })
              )
            }
          />
          <Button
            className="width-fill mag-b-8"
            sizeFrame="lg"
            kind="primary"
            fontStyle="bold"
            fontSize="lg"
            label="Dołącz teraz!"
            onClick={send}
          />
        </div>
      </InfoBox>
    );
  }

  if (landingState.who === "client") {
    return (
      <InfoBox
        id="cta-landing"
        className="width-fill mag-4"
        sizeFrame="md"
        style={{ maxWidth: "1136px", marginBlock: "64px", borderRadius: "35px" }}
      >
        <div className="d-flex ai-center gap-32">
          <img className="l-img-box-cta" src="/img/client_eat.jpg" height={400} />
          <div className="d-flex fd-c gap-16">
            <Label
              wrap
              fontColor="black"
              fontStyle="extra-bold"
              fontSize="xl"
              content={`Chcesz zobaczyć aplikację przed premierą?`}
            />
            <Label
              wrap
              fontColor="black"
              style={{ fontSize: "16px" }}
              fontStyle="semibold"
              content={`Dołącz do zamkniętych testów i zyskaj priorytetowy dostęp do zdrowej i naturalnej żywności przed wszystkimi! Zostaw nam swojego emaila, a będziemy Cię informować o postępach prac, oraz kolejnych nowościach związanych z naszym portalem, który ceni zdrowe jedzenie i wspiera lokalną gospodarkę.`}
            />
            <div className="d-flex gap-16 width-fill">
              <InputText
                frameClassName="width-fill"
                sizeFrame="lg"
                type="email"
                autoComplete="email"
                kind="tertiary"
                placeholder="Wprowadź email"
                value={landingState.email}
                error={landingState.error.email}
                onChange={(e) =>
                  dispatch(
                    changeLandingState({
                      email: e.currentTarget.value,
                      error: { email: "", approval: landingState.error.approval },
                    })
                  )
                }
              />
              <Button
                sizeFrame="lg"
                kind="primary"
                fontStyle="bold"
                fontSize="lg"
                label="Dołącz teraz!"
                onClick={send}
              />
            </div>
            <InputCheckBox
              sizeFrame="sml"
              kind="transparent"
              checkboxLabel="Wyrażam zgodę na otrzymywanie newslettera oraz informacji marketingowych od Ogrosa na podany adres e-mail zgodnie z Polityką Prywatności. Subskrypcja jest dobrowolna i mogę wycofać zgodę w każdej chwili."
              fontColor="black"
              fontSize="sml"
              fontStyle="semibold"
              checked={landingState.approval}
              error={landingState.error.approval}
              onChange={(e) =>
                dispatch(
                  changeLandingState({
                    approval: e.target.checked,
                    error: { approval: "", email: landingState.error.email },
                  })
                )
              }
            />
          </div>
        </div>
      </InfoBox>
    );
  }

  return (
    <InfoBox
      id="cta-landing"
      className="width-fill mag-4"
      sizeFrame="md"
      style={{ maxWidth: "1136px", marginBlock: "64px", borderRadius: "35px" }}
    >
      <div className="d-flex ai-center gap-32">
        <img className="l-img-box-cta" src="/img/suppliers.jpg" height={400} />
        <div className="d-flex fd-c gap-16">
          <Label
            wrap
            fontColor="black"
            fontStyle="extra-bold"
            fontSize="xl"
            content={`Chcesz zobaczyć aplikację przed premierą?`}
          />
          <Label
            wrap
            fontColor="black"
            style={{ fontSize: "16px" }}
            fontStyle="semibold"
            content={`Dołącz do zamkniętych testów i zyskaj darmowe miesiące użytkowania po oficjalnym uruchomieniu. Zostaw nam swojego emaila, a będziemy Cię informować o kolejnych nowościach związanych z naszym portalem, który ceni zdrowe jedzenie i wspiera lokalną gospodarkę.`}
          />
          <div className="d-flex gap-16 width-fill">
            <InputText
              frameClassName="width-fill"
              sizeFrame="lg"
              type="email"
              autoComplete="email"
              kind="tertiary"
              placeholder="Wprowadź email"
              value={landingState.email}
              error={landingState.error.email}
              onChange={(e) =>
                dispatch(
                  changeLandingState({
                    email: e.currentTarget.value,
                    error: { email: "", approval: landingState.error.approval },
                  })
                )
              }
            />
            <Button sizeFrame="lg" kind="primary" fontStyle="bold" fontSize="lg" label="Dołącz teraz!" onClick={send} />
          </div>
          <InputCheckBox
            sizeFrame="sml"
            kind="transparent"
            checkboxLabel="Wyrażam zgodę na otrzymywanie newslettera oraz informacji marketingowych od Ogrosa na podany adres e-mail zgodnie z Polityką Prywatności. Subskrypcja jest dobrowolna i mogę wycofać zgodę w każdej chwili."
            fontColor="black"
            fontSize="sml"
            fontStyle="semibold"
            checked={landingState.approval}
            error={landingState.error.approval}
            onChange={(e) =>
              dispatch(
                changeLandingState({
                  approval: e.target.checked,
                  error: { approval: "", email: landingState.error.email },
                })
              )
            }
          />
        </div>
      </div>
    </InfoBox>
  );
};

export default LandingCTAComponent;
