import React from "react";
import * as Icon from "commons/iconManager";
import { useWindowSize } from "hooks/useWindowSize";

const MenuLeftComponent: React.FC = () => {
  const { isMobile } = useWindowSize();

  return (
    <div className="d-flex ai-center">
      <Icon.OgrosaSVG
        height={isMobile ? 28 : 34}
        style={{
          paddingInline: isMobile ? "6px" : "12px",
          marginBottom: isMobile ? "4px" : "8px",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

export default MenuLeftComponent;
