/*import { createStore, applyMiddleware, combineReducers, Store, AnyAction } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
*/
// Example reducers and types
import { routerReducer } from "react-router-redux";
import { configureStore } from "@reduxjs/toolkit";
import { landingReducer } from "reducers/landingReducer";
import { notificationReducer } from "reducers/notificationReducer";

export let store;

export const getStore = () => {
  store = configureStore({
    reducer: {
      landing: landingReducer,
      notification: notificationReducer,
      router: routerReducer,
    },
    // Redux Toolkit includes 'redux-thunk' by default, so you don't need to add it separately
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development
  });
  
  return store;
};
