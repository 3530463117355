
export type TypeMessageBoxButton =
  | "None"
  | "Ok"
  | "Cancel"
  | "OkCancel"
  | "AbortRetryIgnore"
  | "YesNoCancel"
  | "YesNo"
  | "RetryCancel"
  | "Select"
  | "SelectCancel"
  | "ApproveCancel";

export type TypeMessageBoxIcon = "None" | "Success" | "Error" | "Report" | "Information" | "Question" | "Warning";

export type TypeDialogResult = "Ok" | "Yes" | "No" | "Retry" | "Ignore" | "Cancel" | "Abort" | "Approve" | "Select";

export enum StatusOrder {
  New,
  InProgress,
  Ready,
  Sent,
  WaitingForPayment,
  Completed,
  Canceled,
  All,
}

export enum AvailableType {
  Available,
  TemporarilyUnavailable,
  Unavailable,
}

export enum DeliveryType {
  PickupOnSite,
  PickUpAtThePoint,
  Post,

  All,
}


export enum MessageStatusType {
  Sent = 0,
  Delivered = 1,
  Read = 2,
}

export enum NotificationType {
  None = 0,
  Info = 1,
  Warning = 2,
  Success = 3,
  Error = 4
}

export type SizeFrame = "xsml" | "xsm" | "sml" | "sm" | "md" | "lg" | "xl";

export type KindType = "primary" | "secondary" | "text" | "approve" | "error" | "tertiary" | "transparent";
export type KindTagType = KindType | "green" | "yellow" | "blue" | "red";

export type IconPositionType = "left" | "right";

export type FontStyle =
  | "italic"
  | "regular"
  | "medium"
  | "medium-italic"
  | "semibold"
  | "semibold-italic"
  | "bold"
  | "bold-italic"
  | "extra-bold"
  | "extra-bold-italic";

export type FontSize = "xxsml" | "xsml" | "sml" | "sm" | "md" | "lg" | "xl" | "xxl";

export type FontColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "green"
  | "yellow"
  | "orange"
  | "blue"
  | "red"
  | "gray"
  | "white"
  | "black"
  | "tooltip";
