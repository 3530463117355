import React from "react";
import * as Icon from "commons/iconManager";
import { Notify } from "models/Notify";
import { Label } from "controls/Label";
import { NotificationType } from "models/Dictionaries";

const NotificationItemComponent: React.FC<{ notification: Notify }> = ({ notification }) => {
  const getStyle = () => {
    switch (notification.type) {
      case NotificationType.Error:
        return "notification-error";
      case NotificationType.Info:
        return "notification-info";
      case NotificationType.Success:
        return "notification-success";
      case NotificationType.Warning:
        return "notification-warning";
      default:
        return "notification-none";
    }
  };
  const getIcon = () => {
    if (!notification.showIcon || notification.type === NotificationType.None) return <></>;
    switch (notification.type) {
      case NotificationType.Error:
        return <Icon.ErrorSVG className="notification-icon" />;
      case NotificationType.Info:
        return <Icon.InfoSVG className="notification-icon" />;
      case NotificationType.Success:
        return <Icon.CheckCircleSVG className="notification-icon" />;
      case NotificationType.Warning:
        return <Icon.WarningSVG className="notification-icon" />;
    }
  };
  const showDate = () => {
    const data = new Date(notification.date);
    if (data.toFormatMoment("DD.MM.yyyy") === new Date().toFormatMoment("DD.MM.yyyy")) {
      return (
        <Label
          wrap
          fontSize="xsml"
          fontColor={notification.type === NotificationType.None || notification.isRead ? "primary" : "black"}
          content={data.toFormatMoment("HH:mm:ss")}
        />
      );
    }
    return (
      <Label
        wrap
        fontSize="xsml"
        fontColor={notification.type === NotificationType.None || notification.isRead ? "primary" : "black"}
        content={data.toFormatMoment("DD MMM yyyy r. HH:mm:ss")}
      />
    );
  };

  return (
    <div className={getStyle()}>
      <div className="width-fill d-flex gap-8 ai-center width-fill">
        {getIcon()}
        <div className="d-flex fd-c width-fill">
          <Label
            wrap
            fontSize="sml"
            fontStyle="bold"
            fontColor={notification.type === NotificationType.None ? "primary" : "black"}
            content={notification.title}
          />
          {showDate()}
        </div>
      </div>
      <div className="d-flex pad-4">
        <Label
          className="text-max-line-4"
          fontSize="sm"
          wrap
          fontStyle="semibold"
          fontColor={notification.type === NotificationType.None ? "primary" : "black"}
          content={notification.content}
        />
      </div>
      {notification.link && (
        <div className="d-flex pad-4" style={{ marginLeft: "auto" }}>
          <Label
            fontSize="sm"
            wrap
            fontStyle="bold"
            fontColor="black"
            content={"Zobacz więcej w zakładce powiadomień"}
            style={{ marginLeft: "auto" }}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationItemComponent;
