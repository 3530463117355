import React from "react";
import * as Icon from "commons/iconManager";
import { FontColor, SizeFrame, TypeMessageBoxIcon } from "models/Dictionaries";
import { Label, StandardLabelProps } from "./Label";

export interface MessageBoxProps extends StandardLabelProps, React.HTMLAttributes<HTMLDivElement> {
  icon?: TypeMessageBoxIcon;
  iconSize?: SizeFrame;
  sizeFrame?: SizeFrame;
  pointer?: boolean;
  backgroundColor?: FontColor | "transparent";
  value?: string;
  children?: React.ReactNode;
}

const InfoBox = React.forwardRef<HTMLDivElement, MessageBoxProps>((props, ref) => {
  const inputRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  React.useImperativeHandle(ref, () => inputRef.current);

  const getIconStyle = () => {
    switch (props.iconSize) {
      case "xsm":
        return " icon-24";
      case "sml":
        return " icon-32";
      case "sm":
        return " icon-40";
      case "md":
        return " icon-48";
      case "lg":
        return " icon-56";
      case "xl":
        return " icon-64";
      default:
        return " icon-80";
    }
  };
  const showIcon = (): JSX.Element => {
    switch (props.icon) {
      case "Success":
        return <Icon.CheckCircleSVG className={`${getIconStyle()} c-green`} />;
      case "Error":
        return <Icon.ErrorSVG className={`${getIconStyle()} c-red`} />;
      case "Question":
        return <Icon.HelpSVG className={`${getIconStyle()} c-blue`} />;
      case "Information":
        return <Icon.InfoSVG className={`${getIconStyle()} c-blue`} />;
      case "Warning":
        return <Icon.WarningSVG className={`${getIconStyle()} c-yellow`} />;
      case "Report":
        return <Icon.ReportSVG className={`${getIconStyle()} c-red`} />;
      default:
        return <></>;
    }
  };
  const getStyle = () => {
    let style = "info-box ";
    switch (props.sizeFrame) {
      case "xsm":
        style += " info-box-24";
        break;
      case "sml":
        style += " info-box-32";
        break;
      case "sm":
        style += " info-box-40";
        break;
      case "md":
        style += " info-box-48";
        break;
      case "lg":
        style += " info-box-56";
        break;
      case "xl":
        style += " info-box-64";
        break;
      default:
        style += " info-box-32";
        break;
    }

    switch (props.backgroundColor) {
      case "primary":
        style += " bg-primary ";
        break;
      case "secondary":
        style += " bg-secondary ";
        break;
      case "tertiary":
        style += " bg-tertiary ";
        break;
      case "green":
        style += " bg-green ";
        break;
      case "yellow":
        style += " bg-yellow ";
        break;
      case "orange":
        style += " bg-orange ";
        break;
      case "blue":
        style += " bg-blue ";
        break;
      case "red":
        style += " bg-red  ";
        break;
      case "gray":
        style += " bg-gray ";
        break;
      case "white":
        style += " bg-white ";
        break;
      case "black":
        style += " bg-black ";
        break;
      case "transparent":
        style += " bg-transparent";
        break;
      default:
        style += " bg-blue ";
        break;
    }

    style += " " + props.className;
    return style;
  };

  return (
    <div ref={inputRef} {...props} className={getStyle()}>
      {showIcon()}
      {typeof props.value === "string" ? (
        <Label
          fontSize={props.fontSize}
          fontStyle={props.fontStyle}
          fontColor={props.fontColor ?? "black"}
          content={props.value}
          pointer={props.pointer}
          wrap={true}
        />
      ) : (
        props.value
      )}
      {props.children}
    </div>
  );
});

export default InfoBox;
